// @ts-check

/**
 * A simple wrapper around localStorage that provides conveniences
 * such as JSON serialization and exception handling.
 */
class StorageModule {
  /**
   * @type {Storage}
   */
  get storage() {
    return window.localStorage
  }

  /**
   * Returns the stored value for the provided key or undefined if none exists.
   *
   * @param {string} key The key of the value to retrieve.
   * @returns {*} The stored value for key, or undefined.
   */
  getItem(key) {
    try {
      const entry = this.storage.getItem(key)
      return (entry && JSON.parse(entry).value) || undefined
    } catch (error) {
      return undefined
    }
  }

  /**
   * Returns a timestamp representing the last time a value was stored for
   * the provided key, or undefined if no value exists.
   *
   * @param {string} key The key of the value to retrieve.
   * @returns {number | undefined} A timestamp for the provided value or
   * undefined if none exists.
   */
  getItemTimestamp(key) {
    try {
      const entry = this.storage.getItem(key)
      return (entry && JSON.parse(entry).timestamp) || undefined
    } catch (error) {
      return undefined
    }
  }

  /**
   * Removes any stored value for the provided key.
   *
   * @param {string} key The key of the value to remove.
   */
  removeItem(key) {
    try {
      this.storage.removeItem(key)
    } catch (error) {
      // Ignore error
    }
  }

  /**
   * Stores the provided value with the provided key.
   *
   * @param {string} key The key of the value to store.
   * @param {*} value The value to store.
   * @returns {boolean} Returns true if the value was successfully stored
   * and false if not.
   */
  setItem(key, value) {
    try {
      const entry = { value, timestamp: Date.now() }
      this.storage.setItem(key, JSON.stringify(entry))
      return true
    } catch (error) {
      return false
    }
  }
}

/**
 * A simple wrapper around localStorage that provides conveniences such as
 * JSON serialization and exception handling.
 *
 * Entries are timestamped and timestamps may be retrieved by with the
 * `getItemTimestamp()` method.
 */
const storage = new StorageModule()

export default storage
