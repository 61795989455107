import { IoIosPricetag } from 'react-icons/io'
import basicEditor from '../blocks/basic-editor'
import mediaField from '../fields/media'
import socialMedia from '../fields/social-media'
import linkField from '../fields/link'
import imageField from '../fields/image'

export default {
  title: 'Product Category',
  name: 'productCategory',
  type: 'document',
  icon: IoIosPricetag,
  fieldsets: [
    {
      name: 'landing',
      title: 'Landing Page'
    }
  ],
  fields: [
    {
      title: 'CBD',
      name: 'cbd',
      type: 'boolean',
      description:
        'If CBD is enabled, this record will not be shown on the non-CBD storefront.'
    },
    {
      title: 'Title',
      name: 'title',
      type: 'string'
    },
    {
      title: 'Slug',
      name: 'slug',
      type: 'slug',
      description: 'The URL path this page will exist at',
      options: {
        source: 'title',
        maxLength: 96
      },
      validation: Rule => Rule.required().error('A slug is required.')
    },
    {
      title: 'Products',
      name: 'products',
      type: 'array',
      of: [
        {
          title: 'Product',
          name: 'product',
          type: 'reference',
          to: [{ type: 'product' }]
        }
      ]
    },
    {
      title: 'Testimonials Section',
      name: 'showTestimonials',
      type: 'boolean',
      description:
        'Display the Testimonials section for all products in this category. The testimonials need to be configured separately in the "Testimonials" menu.'
    },
    {
      title: 'Press Section',
      name: 'showPress',
      type: 'boolean',
      description:
        'Display the Press section for all products in this category.'
    },
    {
      name: 'backgroundColors',
      title: 'Background Colors',
      type: 'array',
      of: [
        {
          type: 'color'
        }
      ]
    },
    {
      name: 'backgroundImages',
      title: 'Background Images',
      description:
        'background images to appear in the background (used for clouds). The first image will move at normal speed, and each subsequent assets speed will be halved.',
      type: 'array',
      of: [
        { name: 'backgroundImage', title: 'Background Image', type: 'image' }
      ]
    },
    {
      title: 'Menu',
      name: 'menu',
      type: 'array',
      of: [linkField],
      description: 'Overrides default category product menu with custom links'
    },
    {
      title: 'Intro Title',
      name: 'introTitle',
      type: 'string',
      fieldset: 'landing'
    },
    {
      ...mediaField,
      title: 'Intro Hero',
      name: 'introHero',
      fieldset: 'landing',
      description: 'Sparkling Water hero defaults to the staircase splash'
    },
    {
      ...basicEditor,
      title: 'Intro Text',
      name: 'introText',
      fieldset: 'landing'
    },
    {
      ...socialMedia,
      title: 'Intro Media',
      name: 'introMedia',
      fieldset: 'landing'
    },
    {
      ...linkField,
      title: 'Intro CTA',
      name: 'introCta',
      fieldset: 'landing'
    },
    {
      title: 'Outro Title',
      name: 'outroTitle',
      type: 'string',
      fieldset: 'landing'
    },
    {
      ...basicEditor,
      title: 'Outro Text',
      name: 'outroText',
      fieldset: 'landing'
    },
    {
      ...linkField,
      title: 'Outro CTA',
      name: 'outroCta',
      fieldset: 'landing'
    },
    {
      ...imageField,
      title: 'Outro Image',
      name: 'outroImage',
      fieldset: 'landing'
    },
    {
      title: 'Scrolling Marquees',
      name: 'marquees',
      description: 'Overrides the default marquees',
      type: 'array',
      of: [{ type: 'string' }],
      validation: Rule =>
        Rule.max(4).error("Landing page can't have more than 4 marquees"),
      fieldset: 'landing'
    },
    {
      title: 'Featured Products',
      name: 'featuredProducts',
      type: 'object',
      fields: [
        {
          title: 'Title',
          name: 'title',
          type: 'string'
        },
        {
          ...basicEditor,
          title: 'Text',
          name: 'text'
        },
        {
          title: 'Products',
          name: 'products',
          type: 'array',
          of: [
            {
              type: 'reference',
              to: [{ type: 'product' }]
            }
          ]
        },
        {
          ...linkField,
          title: 'Optional CTA',
          name: 'cta'
        }
      ],
      fieldset: 'landing'
    },
    {
      title: 'Ingredient Heading Override',
      name: 'ingredients',
      type: 'object',
      fields: [
        {
          title: 'Title',
          name: 'title',
          type: 'string'
        },
        {
          title: 'Sub Title',
          name: 'subTitle',
          type: 'string'
        }
      ]
    }
  ]
}
