/**
 * This file aims to provide a standard interface for referencing environment
 * variables.
 *
 * Over time we've seen a number of mysterious bugs that were eventually traced
 * to missing environment variables. The goal of this configuration is avoid
 * such bugs by asserting at build time that all required variables are
 * defined.
 *
 * Example usage:
 *
 * ```
 * // Don't do this
 * const myVar = process.env.MY_VAR;
 *
 * // Do do this
 * import env from "env";
 *
 * const myVar = env.myVar;
 * ```
 */

/**
 * ENV here is equivalent to process.env--it's an object containing all
 * environment variables that the app will use.
 *
 * We're not able to iterate over process.env at build time and must explicitly
 * reference variables on process.env by their accessors in dot notation.
 *
 * Sanity will automatically inject env vars prefixed with 'SANITY_STUDIO_',
 * however the method of injection is static replacement: Strings like
 * 'process.env.SANITY_STUDIO_MY_VAR' are stripped out and replaced with
 * their corresponding values (i.e. the value of 'SANITY_STUDIO_MY_VAR').
 *
 * @link https://www.sanity.io/docs/environment-variables#55b660b13cc7
 */
// prettier-ignore
function buildEnv(env) {
  return {
    /**
     * Sanity config
     */
    // project details
    SANITY_STUDIO_PROJECT_ID: env.SANITY_STUDIO_PROJECT_ID,
    SANITY_STUDIO_API_VERSION: env.SANITY_STUDIO_API_VERSION,
    SANITY_STUDIO_API_DATASET: env.SANITY_STUDIO_API_DATASET,
    // preview URLs
    SANITY_STUDIO_PREVIEW_LOCAL_URL: env.SANITY_STUDIO_PREVIEW_LOCAL_URL,
    SANITY_STUDIO_PREVIEW_PRODUCTION_URL: env.SANITY_STUDIO_PREVIEW_PRODUCTION_URL,
    SANITY_STUDIO_PREVIEW_STAGING_URL: env.SANITY_STUDIO_PREVIEW_STAGING_URL,
    /**
     * Shopify API credentials
     */
    // production store (takearecess.myshopify.com)
    SANITY_STUDIO_SHOPIFY_ENDPOINT: env.SANITY_STUDIO_SHOPIFY_ENDPOINT,
    SANITY_STUDIO_SHOPIFY_TOKEN: env.SANITY_STUDIO_SHOPIFY_TOKEN,
    // development store (takearecess-dev.myshopify.com)
    SANITY_STUDIO_SHOPIFY_DEVELOPMENT_ENDPOINT: env.SANITY_STUDIO_SHOPIFY_DEVELOPMENT_ENDPOINT,
    SANITY_STUDIO_SHOPIFY_DEVELOPMENT_TOKEN: env.SANITY_STUDIO_SHOPIFY_DEVELOPMENT_TOKEN,
    /**
     * Netlify site details
     */
    // Deployment details
    CONTEXT: env.CONTEXT,
    DEPLOY_URL: env.DEPLOY_URL,
    HEAD: env.HEAD,
    REVIEW_ID: env.REVIEW_ID,
    SITE_NAME: env.SITE_NAME,
    // takearecess.com
    SANITY_STUDIO_PRODUCTION_API_ID: env.SANITY_STUDIO_PRODUCTION_API_ID,
    SANITY_STUDIO_PRODUCTION_HOOK_ID: env.SANITY_STUDIO_PRODUCTION_HOOK_ID,
    SANITY_STUDIO_PRODUCTION_SITE_NAME: env.SANITY_STUDIO_PRODUCTION_SITE_NAME,
    // staging.takearecess.com
    SANITY_STUDIO_STAGING_API_ID: env.SANITY_STUDIO_STAGING_API_ID,
    SANITY_STUDIO_STAGING_HOOK_ID: env.SANITY_STUDIO_STAGING_HOOK_ID,
    SANITY_STUDIO_STAGING_SITE_NAME: env.SANITY_STUDIO_STAGING_SITE_NAME,
    // takearecess.co
    SANITY_STUDIO_SCRUBBED_PRODUCTION_API_ID: env.SANITY_STUDIO_SCRUBBED_PRODUCTION_API_ID,
    SANITY_STUDIO_SCRUBBED_PRODUCTION_HOOK_ID: env.SANITY_STUDIO_SCRUBBED_PRODUCTION_HOOK_ID,
    SANITY_STUDIO_SCRUBBED_PRODUCTION_SITE_NAME: env.SANITY_STUDIO_SCRUBBED_PRODUCTION_SITE_NAME,
    // pressplaytopauseyourthoughts.com
    SANITY_STUDIO_POOLSIDE_PRODUCTION_API_ID: env.SANITY_STUDIO_POOLSIDE_PRODUCTION_API_ID,
    SANITY_STUDIO_POOLSIDE_PRODUCTION_HOOK_ID: env.SANITY_STUDIO_POOLSIDE_PRODUCTION_HOOK_ID,
    SANITY_STUDIO_POOLSIDE_PRODUCTION_SITE_NAME: env.SANITY_STUDIO_POOLSIDE_PRODUCTION_SITE_NAME,
  }
}

function getNetlifySites(env) {
  if (env.CONTEXT === 'branch-deploy') {
    const url = `https://${env.HEAD}--${env.SITE_NAME}.netlify.app/`

    return [
      {
        title: `Preview for "${env.HEAD}" `,
        url: url,
        apiId: env.SANITY_STUDIO_STAGING_API_ID,
        buildHookId: `${env.SANITY_STUDIO_STAGING_HOOK_ID}?trigger_branch=${env.HEAD}`,
        name: env.SANITY_STUDIO_STAGING_SITE_NAME,
        branch: env.HEAD
      }
    ]
  }

  if (env.CONTEXT === 'deploy-preview') {
    const url = `https://deploy-preview-${env.REVIEW_ID}--${env.SITE_NAME}.netlify.app/`

    return [
      {
        title: `Preview for !${env.REVIEW_ID} (${env.HEAD}) `,
        url: url,
        apiId: env.SANITY_STUDIO_STAGING_API_ID,
        buildHookId: `${env.SANITY_STUDIO_STAGING_HOOK_ID}?trigger_branch=${env.HEAD}`,
        name: env.SANITY_STUDIO_STAGING_SITE_NAME,
        branch: env.HEAD
      }
    ]
  }

  if (env.SANITY_STUDIO_API_DATASET === 'staging') {
    return [
      {
        title: 'staging.takearecess.com',
        url: 'https://staging.takearecess.com',
        apiId: env.SANITY_STUDIO_STAGING_API_ID,
        buildHookId: env.SANITY_STUDIO_STAGING_HOOK_ID,
        name: env.SANITY_STUDIO_STAGING_SITE_NAME
      }
    ].filter(o => o.apiId && o.buildHookId && o.name)
  }

  if (env.SANITY_STUDIO_API_DATASET === 'production') {
    return [
      {
        title: 'takearecess.com',
        url: 'https://takearecess.com',
        apiId: env.SANITY_STUDIO_PRODUCTION_API_ID,
        buildHookId: env.SANITY_STUDIO_PRODUCTION_HOOK_ID,
        name: env.SANITY_STUDIO_PRODUCTION_SITE_NAME
      },

      {
        title: 'takearecess.co',
        url: 'https://takearecess.co',
        apiId: env.SANITY_STUDIO_SCRUBBED_PRODUCTION_API_ID,
        buildHookId: env.SANITY_STUDIO_SCRUBBED_PRODUCTION_HOOK_ID,
        name: env.SANITY_STUDIO_SCRUBBED_PRODUCTION_SITE_NAME
      }
    ]
  }

  return []
}

/**
 * Variables that are required by the studio.
 */
const REQUIRED_VARIABLES = [
  'SANITY_STUDIO_PREVIEW_LOCAL_URL',
  'SANITY_STUDIO_PREVIEW_PRODUCTION_URL',
  'SANITY_STUDIO_PREVIEW_STAGING_URL',
  'SANITY_STUDIO_PROJECT_ID',
  'SANITY_STUDIO_SHOPIFY_DEVELOPMENT_ENDPOINT',
  'SANITY_STUDIO_SHOPIFY_DEVELOPMENT_TOKEN',
  'SANITY_STUDIO_SHOPIFY_ENDPOINT',
  'SANITY_STUDIO_SHOPIFY_TOKEN',
  'SANITY_STUDIO_STAGING_API_ID',
  'SANITY_STUDIO_STAGING_HOOK_ID',
  'SANITY_STUDIO_STAGING_SITE_NAME',
  'SANITY_STUDIO_API_DATASET'
]

/**
 * Checks for required variables and returns a formatted env object.
 *
 * To add a new var, add it to the ENV constant and then include it in the
 * returned object here. If your variable is required by the studio, add it to
 * REQUIRED as well.
 *
 * @throws Will throw if any var included in REQUIRED_VARIABLES is not
 *         defined on env.
 *
 * @param {object} env process.env or an equivalent object.
 * @returns {object}
 */
export default function getEnv(processEnv = process.env) {
  const env = buildEnv(processEnv)
  const missing = REQUIRED_VARIABLES.filter(variable => !env[variable])

  if (missing.length > 0) {
    throw new Error(
      `Missing required environment variables: ${missing.join(', ')}`
    )
  }

  return {
    preview: {
      local: {
        url: env.SANITY_STUDIO_PREVIEW_LOCAL_URL
      },
      production: {
        url: env.SANITY_STUDIO_PREVIEW_PRODUCTION_URL
      },
      staging: {
        url: env.SANITY_STUDIO_PREVIEW_STAGING_URL
      }
    },
    apiVersion:
      env.SANITY_STUDIO_API_VERSION ||
      new Date().toISOString().split('T').shift(),
    projectId: env.SANITY_STUDIO_PROJECT_ID,
    dataset: env.SANITY_STUDIO_API_DATASET,
    shopify: {
      production: {
        uri: env.SANITY_STUDIO_SHOPIFY_ENDPOINT,
        token: env.SANITY_STUDIO_SHOPIFY_TOKEN
      },
      development: {
        uri: env.SANITY_STUDIO_SHOPIFY_DEVELOPMENT_ENDPOINT,
        token: env.SANITY_STUDIO_SHOPIFY_DEVELOPMENT_TOKEN
      }
    },
    netlify: {
      sites: getNetlifySites(env).filter(
        o => o.apiId && o.buildHookId && o.name
      )
    }
  }
}
