import { SHOPIFY_GIFT_CARD_PRODUCT_TYPE } from '../../../src/constants'
import decodeId from '~/util/api/decode-id'

export default {
  productById: state => id => {
    return state.products.find(p => p._id === decodeId(id))
  },
  productsByCategory: state => category => {
    return state.products.filter(
      p => p.category === category && !p.hideFromCollection
    )
  },
  subscriptionById: state => id => {
    // The id from the arguments comes from Sanity which can still
    // contain the old base64 encoded IDs so we make sure
    // to decode it if it's not already in the gid:// format.
    return state.subscriptions.find(s => s.id === decodeId(id))
  },
  cartHasGiftCard(state) {
    return (
      state.cart.lines.edges.find(
        ({ node }) =>
          node.merchandise.product.productType ===
          SHOPIFY_GIFT_CARD_PRODUCT_TYPE
      ) !== undefined
    )
  },
  cartCanBeGiftOrder(state, getters) {
    return (
      state.cart?.lines?.edges?.length > 0 &&
      !getters.cartHasSubscriptionItem &&
      !getters.cartHasGiftCard
    )
  },
  cartHasSubscriptionItem(state) {
    return state.cart.lines.edges.some(
      ({ node }) =>
        !!node.sellingPlanAllocation?.sellingPlan?.recurringDeliveries
    )
  },
  promoProductByShopifySKU: state => sku => {
    return state.promoShopifyProducts.find(p => {
      if (p.variants && p.variants.edges) {
        return p.variants.edges.find(v => {
          return v.node.sku === sku
        })
      }

      return false
    })
  },
  productByShopifyId: state => id => {
    return state.products.find(p => {
      const shopify = p.shopify
      if (!shopify) {
        return false
      }
      const product = shopify.product
      const subscription = shopify.subscription
      const matchesProduct = product ? decodeId(product.id) === id : false
      const matchesSub = subscription ? decodeId(subscription.id) === id : false
      return matchesProduct || matchesSub
    })
  },

  promotionSKUs(state) {
    return state.promotionalProducts
  },

  findPromotionalSKUs: state => size => {
    const promotionalProduct = state.promotionalProducts.find(
      promotionalProduct => {
        return promotionalProduct.size === size
      }
    )

    if (promotionalProduct) {
      return promotionalProduct.targetProductSkus || []
    }
  },

  findLimitedPromotionalSKUs: state => size => {
    const promotionalProduct = state.promotionalProducts.find(
      promotionalProduct => {
        return promotionalProduct.size === size
      }
    )

    if (promotionalProduct) {
      return promotionalProduct.limitedTargetProductSkus || []
    }
  },

  allPromotionSizes(state) {
    const allPromotionSizes = []
    state.promotionalProducts.forEach(promotionalProduct => {
      if (!allPromotionSizes.includes(promotionalProduct.size)) {
        allPromotionSizes.push(promotionalProduct.size)
      }
    })
    return allPromotionSizes.sort()
  },

  allPromotionSKUs(state) {
    const allPromotionSKUs = []
    state.promotionalProducts.forEach(promotionalProduct => {
      const targetSkus = promotionalProduct.targetProductSkus || []
      targetSkus
        .concat(promotionalProduct.limitedTargetProductSkus || [])
        .forEach(sku => {
          if (!allPromotionSKUs.includes(sku)) {
            allPromotionSKUs.push(sku)
          }
        })
    })
    return allPromotionSKUs
  },

  rebuyProducts(state) {
    return state.rebuyProducts
  }
}
