<template>
  <thumb
    v-if="product.thumb"
    :id="thumbId"
    ref="thumb"
    tag="recess-link"
    :to="to || documentPath(product)"
    :class="classes"
    :thumb="product.thumb"
    :hover-images="hoverImages"
    :hover-title="hoverTitle"
    :set-colors-manually="setColorsManually"
    :colors="documentColors(product)"
    :title="title"
    :inline="inline"
    :square="square"
    class="text-center product-thumb link--always-active"
    @mouseover.native="over"
    @mouseleave.native="out"
    @click.native="click"
  >
    <float :stopped="!float" offset="-2%" speed="3" reverse :on-hover="float">
      <sanity-media
        v-bind="product.thumb"
        :max-width="maxWidth"
        :max-height="maxHeight"
        :not-lazy="notLazy"
        :no-ratio="square || noRatio"
        :ratio="ratio"
        :contain="square || contain"
        :swiper="swiper"
        :image-class="imageClass"
        :caption="product.title"
        class="product-thumb__image"
        @loaded="$emit('loaded')"
      />
    </float>
    <template
      v-if="$device.isDesktop && galleryHover && product.galleryThumb"
      #bottom
    >
      <fade appear>
        <div
          v-if="galleryThumbVisible"
          v-move-dom
          :style="getGalleryStyle()"
          :class="getGalleryClass()"
          class="product-thumb__gallery-thumb has-window"
        >
          <float class="product-thumb__gallery-float">
            <window-3d small class="product-thumb__window">
              <sanity-media
                v-bind="product.galleryThumb"
                no-ratio
                cover
                fluid
              />
            </window-3d>
          </float>
        </div>
      </fade>
    </template>
  </thumb>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ProductThumb',

  props: {
    product: {
      default: null,
      type: Object,
      required: true
    },
    title: {
      default: undefined,
      type: String
    },
    to: {
      default: undefined,
      type: String
    },
    maxWidth: {
      default: 640,
      type: [Number, String]
    },
    maxHeight: {
      default: 640,
      type: [Number, String]
    },
    float: {
      default: false,
      type: Boolean
    },
    inline: {
      default: false,
      type: Boolean
    },
    square: {
      default: false,
      type: Boolean
    },
    noRatio: {
      default: false,
      type: Boolean
    },
    ratio: {
      type: Number,
      default: undefined,
      required: false
    },
    notLazy: {
      default: false,
      type: Boolean
    },
    contain: {
      default: false,
      type: Boolean
    },
    swiper: {
      default: false,
      type: Boolean
    },
    imageClass: {
      default: undefined,
      type: String
    },
    setColorsManually: {
      default: false,
      type: Boolean
    },
    hoverTitle: {
      default: false,
      type: Boolean
    },
    hoverImages: {
      default: false,
      type: Boolean
    },
    galleryHover: {
      default: false,
      type: Boolean
    },
    preventRoute: {
      default: false,
      type: Boolean
    }
  },

  data() {
    return {
      active: false,
      thumbId: `product-thumb-${this.$_uid}`,
      galleryThumbVisible: false
    }
  },

  computed: {
    ...mapState('screen', {
      touch: state => state.touch,
      width: state => state.width,
      height: state => state.height
    }),
    classes() {
      const thumb = this.product.thumb
      const hovers = thumb && thumb.hovers
      return {
        'product-thumb--hovers': hovers && hovers.length > 0,
        'is-prevent-route': this.preventRoute
      }
    },
    url() {
      return this.documentPath(this.product)
    }
  },

  mounted() {
    this.$bus.$on('scroll', this.scroll)
  },

  beforeDestroy() {
    this.$bus.$off('scroll', this.scroll)
  },

  methods: {
    setColors() {
      const thumb = this.$refs.thumb
      if (thumb) {
        thumb.setColors()
      }
    },
    unsetColors() {
      const thumb = this.$refs.thumb
      if (thumb) {
        thumb.unsetColors()
      }
    },
    showGalleryThumb() {
      this.galleryThumbVisible = true
    },
    hideGalleryThumb() {
      this.galleryThumbVisible = false
    },
    getGalleryStyle() {
      const rect = this.$el.getBoundingClientRect()
      const screenXCenter = this.width / 2
      const screenYCenter = this.height / 2
      const elXCenter = rect.left + rect.width / 2
      const elYCenter = rect.top + rect.height / 2
      const left = `${
        screenXCenter <= elXCenter
          ? rect.left - rect.width / 2
          : rect.left + rect.width / 2
      }px`
      const top = `${
        screenYCenter <= elYCenter
          ? rect.top - rect.height / 2
          : rect.top + rect.height / 2
      }px`
      return { left, top }
    },
    getGalleryClass() {
      const rect = this.$el.getBoundingClientRect()
      const screenXCenter = this.width / 2
      const screenYCenter = this.height / 2
      const elXCenter = rect.left + rect.width / 2
      const elYCenter = rect.top + rect.height / 2
      const x = screenXCenter <= elXCenter ? 'left' : 'right'
      const y = screenYCenter <= elYCenter ? 'top' : 'bottom'
      return `is-${x} is-${y}`
    },
    over() {
      if (this.active) {
        return
      }
      this.active = true
      if (!this.setColorsManually) {
        this.setColors()
      }
      if (this.galleryHover) {
        this.showGalleryThumb()
      }
      this.$emit('over', { colors: this.documentColors(this.product) })
    },
    out() {
      if (!this.active) {
        return
      }
      this.active = false
      if (!this.setColorsManually) {
        this.unsetColors()
      }
      if (this.galleryHover) {
        this.hideGalleryThumb()
      }
      this.$emit('out')
    },
    click(e) {
      if (process.env.external) {
        return
      }
      const url = this.to || this.url
      e.preventDefault()
      this.$router.push(url)
    },
    scroll() {
      if (this.galleryThumbVisible) {
        this.hideGalleryThumb()
      }
    }
  }
}
</script>

<style>
.product-thumb__image img,
.product-thumb .thumb__content {
  max-height: 350px;
}

.product-thumb.is-prevent-route,
.is-scrolling .product-thumb {
  pointer-events: none;
}

.is-active .product-thumb {
  pointer-events: auto;
}

.product-thumb__gallery-thumb,
.product-thumb__gallery-float {
  display: inline-block;
}

.product-thumb__gallery-thumb {
  pointer-events: none;
  position: fixed;
  z-index: theme('zIndex.splash');
}

.product-thumb__gallery-thumb.is-top.is-left {
  transform: translate(-50%, -50%);
}

.product-thumb__gallery-thumb.is-top.is-right {
  transform: translate(50%, -50%);
}

.product-thumb__gallery-thumb.is-bottom.is-right {
  transform: translate(50%, 50%);
}

.product-thumb__gallery-thumb.is-bottom.is-left {
  transform: translate(-50%, 50%);
}
</style>
