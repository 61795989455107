import cloneDeep from 'lodash/cloneDeep'
import {
  SHOPIFY_ORDER_CUSTOM_ATTRIBUTE_GIFT_TEXT_KEY,
  SHOPIFY_ORDER_CUSTOM_ATTRIBUTE_GIFT_EMAIL_KEY
} from '../../../src/constants'
import getters from './getters'
import eventBus from '~/plugins/event-bus'
import formatSubscriptions from '~/util/api/format-subscriptions'
import fetchRebuyProducts from '~/util/rebuy/fetch-rebuy-products'
import formatProducts from '~/util/api/format-products'
import sortLineItems from '~/util/shopify/sort-line-items'
import getDiscountCodeFromSettings from '~/util/sanity/get-discount-code-from-settings'
import formatSanityProduct from '~/util/sanity/format-sanity-products'
import productsQuery from '~/sanity/queries/products'
import promotionalProductsQuery from '~/sanity/queries/promotional-products'
import productMenuQuery from '~/sanity/queries/product-menu'
import shopMenuQuery from '~/sanity/queries/shop-menu'
import featuredProductQuery from '~/sanity/queries/featured-product'
import ingredientsQuery from '~/sanity/queries/ingredients'
import shopifyProductsQuery from '~/shopify/queries/products.gql'
import subscriptionsQuery from '~/shopify/queries/subscriptions.gql'
import cartQuery from '~/shopify/queries/cart.gql'
import cartAttributesUpdateMutation from '~/shopify/mutations/cartAttributesUpdate.gql'
import cartLinesUpdateMutation from '~/shopify/mutations/cartLinesUpdate.gql'
import cartLinesAddMutation from '~/shopify/mutations/cartLinesAdd.gql'
import cartLinesRemoveMutation from '~/shopify/mutations/cartLinesRemove.gql'
import { withCache } from '~/util/cache'
import formatLineItemInputs from '~/util/shopify/format-line-item-inputs'
import formatLineItemInputsForUpdate from '~/util/shopify/format-line-item-inputs-for-update'

// this seems to be the best way to guarantee we fetch all
// subscriptions. There are SSR issues with using apollo's
// `fetchMore` function.
async function fetchSubscriptions(apolloClient) {
  const subscriptions = []
  let subscriptionCursor
  let hasMoreSubscriptions = true

  while (hasMoreSubscriptions) {
    const subscriptionResponse = await apolloClient.query({
      query: subscriptionsQuery,
      variables: {
        cursor: subscriptionCursor,
        query: 'Subscription AND NOT tag:Subscription'
      }
    })

    const { data } = subscriptionResponse

    subscriptions.push(...formatSubscriptions(data.subscriptions.edges))

    hasMoreSubscriptions = data.subscriptions.pageInfo.hasNextPage

    subscriptionCursor =
      data.subscriptions.edges[data.subscriptions.edges.length - 1].cursor
  }

  return subscriptions
}

async function fetchPromoShopifyProducts(apolloClient) {
  const promoShopifyProducts = []
  let productCursor
  let hasMoreProducts = true
  let productsResponse

  while (hasMoreProducts) {
    productsResponse = await apolloClient.query({
      query: shopifyProductsQuery,
      variables: {
        cursor: productCursor,
        query: 'tag:promotional'
      }
    })

    const { data } = productsResponse

    promoShopifyProducts.push(...formatProducts(data.products.edges))

    hasMoreProducts = data.products.pageInfo.hasNextPage

    if (data.products.edges.length > 0) {
      productCursor = data.products.edges[data.products.edges.length - 1].cursor
    }
  }

  return promoShopifyProducts
}

export default {
  async init({ dispatch, commit }) {
    const products = await dispatch('fetchProducts')
    const subscriptions = await dispatch('fetchSubscriptions')
    const featuredProduct = await dispatch('fetchFeaturedProduct')
    const promoShopifyProducts = await dispatch('fetchPromoShopifyProducts')
    const promotionalProducts = await dispatch('fetchPromotionalProducts')
    const ingredients = await dispatch('fetchIngredients')
    const productMenu = await dispatch('fetchProductMenu')
    const shopMenu = await dispatch('fetchShopMenu')

    commit('setPromoShopifyProducts', promoShopifyProducts)
    commit('setProducts', products)
    commit('setSubscriptions', subscriptions)
    commit('setFeaturedProduct', featuredProduct)
    commit('setPromotionalProducts', promotionalProducts)
    commit('setIngredients', ingredients)
    commit('setProductMenu', productMenu)
    commit('setShopMenu', shopMenu)
  },

  nuxtClientInit({ dispatch }) {
    // code that should run on browser init
  },
  async fetchProducts() {
    const products = await this.$sanity.fetch(productsQuery)
    return products.filter(product => formatSanityProduct(product))
  },
  clearRebuyRecommendations({ commit }) {
    commit('setRebuyProducts', [])
  },
  async fetchRebuyRecommendations({ rootState, state, commit }) {
    if (state.cart) {
      const dataSourceId = rootState.settings.rebuy.dataSourceId
      const productIds = state.cart.lines?.edges?.map(
        ({ node }) => node.merchandise.product.id
      )
      const variantIds = state.cart.lines?.edges?.map(
        ({ node }) => node.merchandise.id
      )
      if (dataSourceId && productIds) {
        const allRebuyProducts = await fetchRebuyProducts({
          dataSourceId,
          productIds,
          variantIds
        })
        commit('setRebuyProducts', allRebuyProducts)
      }
    }
  },
  filterRebuyProducts({ state, commit }, edge) {
    const rebuyProducts = state.rebuyProducts.filter(i => {
      return i.id !== edge.node.merchandise.product.id
    })
    commit('setRebuyProducts', rebuyProducts)
  },
  async fetchSubscriptions() {
    const apolloClient = this.app.apolloProvider.defaultClient
    return await withCache('subscriptions', () =>
      fetchSubscriptions(apolloClient)
    )
  },
  async fetchFeaturedProduct() {
    return await this.$sanity.fetch(featuredProductQuery)
  },
  async fetchPromoShopifyProducts() {
    const apolloClient = this.app.apolloProvider.defaultClient
    return await withCache('promoShopifyProducts', () =>
      fetchPromoShopifyProducts(apolloClient)
    )
  },
  async fetchPromotionalProducts() {
    return await this.$sanity.fetch(promotionalProductsQuery)
  },
  async fetchProductMenu() {
    let menu = []
    const { productMenu } = await this.$sanity.fetch(productMenuQuery)
    menu = productMenu
    return menu
  },
  async fetchShopMenu() {
    const shopMenu = await this.$sanity.fetch(shopMenuQuery)
    return shopMenu
  },
  async fetchIngredients() {
    const ingredients = await this.$sanity.fetch(ingredientsQuery)
    return ingredients || []
  },
  setCartId(ctx, id) {
    id = id || this.$storage.getUniversal('cartId')
    this.$storage.setUniversal('cartId', id)
    return id
  },
  /**
   * Given a CheckoutLineItemEdge object, update it or, depending
   * on whether or not the quantity is less than 1, remove it.
   * Increment the quantity if additive and if the item already
   * existed.
   * @function
   */
  async addToCart({ state, dispatch }, { edge, additive = false }) {
    if (!state.cart || state.cart === null) {
      const toast = this.$toast.info(
        'your cart has expired, setting up a new one for you now...'
      )

      try {
        await new Promise((resolve, reject) => {
          const timeout = setTimeout(() => {
            reject(new Error('Cart creation timed out.'))
          }, 8000)

          eventBus.$once('createCartSuccess', () => {
            clearTimeout(timeout)
            resolve()
          })

          eventBus.$once('createCartFailure', error => {
            clearTimeout(timeout)
            reject(error || new Error('Cart creation failed.'))
          })

          eventBus.$emit('createCart')
        })
      } catch (e) {
        this.$sentry.captureException(e)
        toast.goAway()
        this.$toast.error(
          'We encountered an issue while creating your cart. Please refresh the page and try again.'
        )
        return
      }

      toast.text('your new cart is all set!').goAway(3000)
    }
    const cart = cloneDeep(state.cart) // make non-reactive
    const edges = cart.lines.edges

    const index = edges.findIndex(
      ({ node }) => node.merchandise.id === edge.node.merchandise.id
    )
    const newEdge = index === -1
    if (newEdge) {
      await dispatch('addToCartMutation', { cart, edge })
    } else if (edge.node.quantity < 1) {
      await dispatch('removeFromCartMutation', { cart, edge })
    } else if (!newEdge) {
      // Update Quantity
      if (!newEdge && additive) {
        edge.node.quantity = edges[index].node.quantity + edge.node.quantity
      }
      // Set the correct line ID
      edge.node.id = edges[index].node.id

      await dispatch('updateCartLineMutation', {
        cart,
        edge
      })
    }

    // optimisic update: remove item from rebuy recommendations if it exists there
    dispatch('filterRebuyProducts', edge)
    dispatch('itemAddedToCart')
  },
  async addToCartMutation({ rootState, state, commit }, { cart, edge }) {
    const shopifyClient = this.app.apolloProvider.clients.cart

    const lines = formatLineItemInputs([edge])
    const cartId = rootState.storage.cartId
    const variables = {
      lines,
      cartId
    }
    const optimisticEdges = cart.lines.edges
    optimisticEdges.push(edge)

    try {
      await shopifyClient.mutate({
        mutation: cartLinesAddMutation,
        variables,
        update: (store, { data: { cartLinesAdd } }) => {
          const query = {
            query: cartQuery,
            variables: { id: cartId }
          }
          const data = store.readQuery(query)
          data.node = cartLinesAdd.cart
          store.writeQuery({ ...query, data })
          commit('screen/setOverlay', 'cart', { root: true })
        },
        optimisticResponse: {
          __typename: 'Mutation',
          cartLinesAdd: {
            __typename: 'CartLinesAddPayload',
            cart: {
              __typename: 'Cart',
              ...cart,
              lines: {
                __typename: 'CartLineConnection',
                edges: sortLineItems(optimisticEdges)
              }
            }
          }
        }
      })
    } catch (e) {
      this.$sentry.captureException(e)
      this.app.$toast.global.apolloError(e)
    }
  },
  async removeFromCartMutation({ rootState, state, commit }, { cart, edge }) {
    const shopifyClient = this.app.apolloProvider.clients.cart
    const lineIds = [edge.node.id]
    const cartId = rootState.storage.cartId
    const variables = {
      lineIds,
      cartId
    }

    const optimisticEdges = cart.lines.edges.filter(e => {
      return e.node.id !== edge.node.id
    })

    try {
      await shopifyClient.mutate({
        mutation: cartLinesRemoveMutation,
        variables,
        update: (store, { data: { cartLinesRemove } }) => {
          const query = {
            query: cartQuery,
            variables: { id: cartId }
          }
          const data = store.readQuery(query)
          data.node = cartLinesRemove.cart
          store.writeQuery({ ...query, data })
          commit('screen/setOverlay', 'cart', { root: true })
        },
        optimisticResponse: {
          __typename: 'Mutation',
          cartLinesRemove: {
            __typename: 'CartLinesRemovePayload',
            cart: {
              __typename: 'Cart',
              ...cart,
              lines: {
                __typename: 'BaseCartLineConnection',
                edges: sortLineItems(optimisticEdges)
              }
            }
          }
        }
      })
    } catch (e) {
      this.$sentry.captureException(e)
      this.app.$toast.global.apolloError(e)
    }
  },
  async updateCartLineMutation({ rootState, state, commit }, { cart, edge }) {
    const shopifyClient = this.app.apolloProvider.clients.cart
    const lines = formatLineItemInputsForUpdate([edge])
    const cartId = rootState.storage.cartId
    const variables = {
      lines,
      cartId
    }
    const optimisticEdges = cart.lines.edges.filter(e => {
      return e.node.id !== edge.node.id
    })
    optimisticEdges.push(edge)

    try {
      await shopifyClient.mutate({
        mutation: cartLinesUpdateMutation,
        variables,
        update: (store, { data: { cartLinesUpdate } }) => {
          const query = {
            query: cartQuery,
            variables: { id: cartId }
          }
          const data = store.readQuery(query)
          data.node = cartLinesUpdate.cart
          store.writeQuery({ ...query, data })
          commit('screen/setOverlay', 'cart', { root: true })
        },
        optimisticResponse: {
          __typename: 'Mutation',
          cartLinesUpdate: {
            __typename: 'CartLinesUpdatePayload',
            cart: {
              __typename: 'Cart',
              ...cart,
              lines: {
                __typename: 'BaseCartLineConnection',
                edges: sortLineItems(optimisticEdges)
              }
            }
          }
        }
      })
    } catch (e) {
      this.$sentry.captureException(e)
      this.app.$toast.global.apolloError(e)
    }
  },
  async itemAddedToCart({ state, dispatch }) {
    const hasGiftCard = getters.cartHasGiftCard(state)
    const hasGiftNote = !!state.giftNote.text
    const hasSubscriptionItem = getters.cartHasSubscriptionItem(state)

    if ((hasGiftCard || hasSubscriptionItem) && hasGiftNote) {
      // we don't support having gift notes on orders with gift cards
      // nor with orders that have subscriptions
      await dispatch('giftNoteRemove')
    }
  },

  /**
   * Opens the Shopify checkout URL after appending tracking parameters.
   */
  gotoCheckoutUrl({ rootState, state }) {
    const cart = state.cart

    if (!cart) {
      throw new Error('Something went wrong: Cart not found.')
    }

    const hasSubscriptionItems = getters.cartHasSubscriptionItem(state)

    /**
     * Tracking params to add to the checkout URL.
     */
    let params = {
      ga4MeasurementId: process.env.ga4MeasurementId,
      _ga: rootState.googleClientId,
      googleClientId: rootState.googleClientId,
      googleSessionId: rootState.googleSessionId,
      non_cbd: process.env.nonCBDSite,
      origin: window.location.href,
      cart_token: cart.id.split('/').slice(-1)
    }

    // Add a discount param if it's available
    const discountParam = process.env.discountParam

    if (discountParam) {
      const discountCode =
        rootState.discountCode ||
        getDiscountCodeFromSettings(
          rootState.settings.discountCodeSettings,
          hasSubscriptionItems
        )

      if (discountCode) {
        params[discountParam] = discountCode
      }
    }

    // Parse and add tracking params stored in local storage
    const localStorageKeys = ['utm', 'aspire']
    for (const key of localStorageKeys) {
      try {
        const entry = localStorage.getItem(key)
        const { value } = (entry && JSON.parse(entry)) || {}
        params = { ...params, ...(value || {}) }
      } catch (error) {
        // Ignore
      }
    }

    // Add Refersion-specific params if they're available
    try {
      if (localStorage.getItem('current_rfsn_lsts')) {
        params.current_rfsn_lsts = localStorage.getItem('current_rfsn_lsts')
        params.rfsn_aid = localStorage.getItem('rfsn_aid')
        params.rfsn_cs = localStorage.getItem('rfsn_cs')
        params.rfsn_ci = localStorage.getItem('rfsn_ci')
        params.rfsn_src = localStorage.getItem('rfsn_src')
      }
    } catch (error) {
      // Ignore
    }

    // Construct the checkout URL
    const url = new URL(cart.checkoutUrl)

    // Append search params
    for (const [name, value] of Object.entries(params)) {
      if (typeof value !== 'undefined' && value !== null) {
        url.searchParams.append(name, String(value))
      }
    }

    // Go to the checkout
    window.location.replace(url)
  },

  trackRebuySuggestedProduct({ commit }, productId) {
    commit('setRebuyTracker', productId)
  },

  clearRebuyTracker({ commit }) {
    commit('setRebuyTracker', null)
  },

  giftNoteEditorOpen({ commit }) {
    commit('setGiftNoteEditorIsOpen', true)
  },

  giftNoteEditorClose({ commit, state }) {
    commit('setGiftNoteEditorIsOpen', false)
    commit('syncGiftNote')
  },

  giftNoteTextChange({ commit }, value) {
    commit('setGiftNoteText', value)
  },

  giftNoteEmailChange({ commit }, value) {
    commit('setGiftNoteEmail', value)
  },

  async giftNoteRemove({ commit, dispatch }, value) {
    commit('setGiftNoteEmail', '')
    commit('setGiftNoteText', '')
    await dispatch('giftNoteSave')
  },

  async giftNoteSave({ state, commit }) {
    commit('setGiftNoteEditorIsOpen', false)

    const { email, text } = state.giftNote

    // Remove gift note attributes to ensure they never
    // get added more than once. This also serves to clear
    // those values when adding a subscription product to the
    // cart.
    const currentAttributes = state.cart.attributes
      .map(({ key, value }) => ({
        key,
        value
      }))
      .filter(
        ({ key }) =>
          ![
            SHOPIFY_ORDER_CUSTOM_ATTRIBUTE_GIFT_EMAIL_KEY,
            SHOPIFY_ORDER_CUSTOM_ATTRIBUTE_GIFT_TEXT_KEY
          ].includes(key)
      )

    let newAttributes = currentAttributes

    // If gift email and text are defined,
    // we add the attributes to the cart
    if (email && text) {
      newAttributes = [
        ...newAttributes,
        {
          key: SHOPIFY_ORDER_CUSTOM_ATTRIBUTE_GIFT_EMAIL_KEY,
          value: email
        },
        {
          key: SHOPIFY_ORDER_CUSTOM_ATTRIBUTE_GIFT_TEXT_KEY,
          value: text
        }
      ]
    }

    const variables = {
      cartId: state.cart.id,
      attributes: newAttributes
    }

    const { data } = await this.app.apolloProvider.clients.cart.mutate({
      mutation: cartAttributesUpdateMutation,
      variables
    })

    const { cart } = data.cartAttributesUpdate
    commit('setCart', cart)
  }
}
