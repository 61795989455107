import link from './fields/link'
import home from './home'
import homeNonCBD from './home-non-cbd'
import page from './page'
import product from './products/product'
import productCategory from './products/product-category'
import featuredProduct from './products/featured-product'
import promotionalProduct from './products/promotional-product'
import buyNow from './products/buy-now'
import ingredients from './ingredients/ingredients'
import ingredient from './ingredients/ingredient'
import whatsInside from './whats-inside'
import labResults from './lab-results'
import whereToBuy from './where-to-buy'
import wholesale from './wholesale'
import press from './press'
import testimonial from './testimonial'
import testimonialsPage from './testimonials-page'
import menus from './menus'
import socialMedia from './social-media'
import siteMeta from './site-meta'
import klaviyo from './klaviyo'
import rebuy from './rebuy'
import signUp from './sign-up'
import siteFooter from './footer'
import globalBanner from './global-banner'
import discountBanner from './discount-banner'
import cart from './cart'
import shopMenu from './shop-menu'
import productCategoryCollection from './product-category-collection'
import scrollIndicator from './scroll-indicator'
import ingredientsWindow from './ingredients-window'
import amazonSettings from './amazon-settings'
import discountCodeSettings from './discount-code-settings'
import form from './form'

export default [
  link,
  cart,
  home,
  homeNonCBD,
  page,
  product,
  productCategory,
  featuredProduct,
  promotionalProduct,
  buyNow,
  ingredients,
  ingredient,
  labResults,
  whatsInside,
  whereToBuy,
  wholesale,
  press,
  testimonial,
  testimonialsPage,
  menus,
  socialMedia,
  siteMeta,
  klaviyo,
  rebuy,
  signUp,
  siteFooter,
  globalBanner,
  discountBanner,
  shopMenu,
  scrollIndicator,
  productCategoryCollection,
  ingredientsWindow,
  amazonSettings,
  discountCodeSettings,
  form
]
