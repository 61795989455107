import { ApolloLink } from '@apollo/client/core'

import {
  createErrorLink,
  createRetryLink,
  createAuthenticatedHttpLink
} from './links'
import cache from '~/apollo/cart-cache'

export default function({ env, $sentry }) {
  return {
    cache,
    link: ApolloLink.from([
      createErrorLink({ $sentry, env }),
      createRetryLink({ maxRetries: 4 }),
      createAuthenticatedHttpLink(env)
    ]),
    defaultHttpLink: false
  }
}
