import groq from 'groq'
import imageField from './image'

export default groq`{
  ...,
  ingredients[]-> {
    ...,
    image{
      ...${imageField}
    }
  }
}`
